import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardActions, DialogContent, IconButton, useTheme, Button, useMediaQuery, Avatar, CardHeader } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import SvgIcon from "@material-ui/core/SvgIcon";

 
import Dialog from "@material-ui/core/Dialog";
import { useStyles } from "./album_layout";
import Markdown from "./Markdown";

import Amplify from "aws-amplify";
import aws_exports from "../aws-exports-fix";
Amplify.configure(aws_exports);
import { Auth } from "aws-amplify";




const LogoutIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6 2h9a2 2 0 0 1 2 2v1a1 1 0 0 1-2 0V4H6v16h9v-1a1 1 0 0 1 2 0v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z" />
      <path d="M16.795 16.295c.39.39 1.02.39 1.41 0l3.588-3.588a1 1 0 0 0 0-1.414l-3.588-3.588a.999.999 0 0 0-1.411 1.411L18.67 11H10a1 1 0 0 0 0 2h8.67l-1.876 1.884a.999.999 0 0 0 .001 1.411z" />
    </SvgIcon>
  );
};


const GoogleIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z" />{" "}
    </SvgIcon>
  );
};


export const userName = (user: any) => user?.attributes?.name;

export const userInitials = (user: any) =>
  user?.attributes?.name
    .split(" ")
    .map((n: string) => n[0])
    .join("");

export const userPicture = (user: any) => {
  // Lets just use initials for now
  // if (user?.username?.includes("Google")) {
  //   return user.attributes.picture;
  // }
  // TODO - FB url returns 404
  // if (user?.username?.includes("Facebook")) {
  //   console.log("userPicture", user.attributes.picture);
  //   const pictureData = JSON.parse(user.attributes.picture);
  //   return pictureData.data.url;
  // }
  return undefined;
};

export const provider = (user: any) => {
  if (user?.username?.includes("Google")) {
    return "Google";
  }
  if (user?.username?.includes("Facebook")) {    
    return "Facebook";
  }
  return undefined;
};


/**
 * Local(non-hosted) render of amplify auth
 */

export interface AuthenticatorProps {
  open: boolean;
  onClose: (value?: string) => void;
}

export const Authenticator = (props: AuthenticatorProps) => {
  const { t } = useTranslation();
  const [user, setUser] = React.useState({});
  const { onClose, open } = props;
  const theme = useTheme();
  const classes = useStyles();
  /**
   * Get current user
   */
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Auth.currentAuthenticatedUser()
        .then((_user) => {
          setUser(_user);
        })
        .catch((error) => {
          if (error !== 'not authenticated') {
            console.log("Authenticator", error);      
          }
        });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleClose = () => {
      onClose();
  };
  
  const wide_screen = useMediaQuery('(min-width:600px)');


  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: theme.palette.text.secondary,
          },
        }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogContent>
          <Card className={classes.card}>
            {user.username && (
              <React.Fragment>
                <CardHeader
                  avatar={<Avatar alt="avatar">{userInitials(user)}</Avatar>}
                  title={provider(user)}                  
                />
                <CardContent>
                  <Markdown>{`${t("sign-in-message")}`}</Markdown>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => handleClose()}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={() => Auth.signOut()}
                    startIcon={<LogoutIcon />}
                  >
                    {t("sign-out")}
                  </Button>
                </CardActions>
              </React.Fragment>
            )}
            {user.username === undefined && (
              <React.Fragment>
                <CardContent>
                  <Markdown color="secondary">{`# ${t("profile")}`}</Markdown>
                  <Markdown>{`${t("sign-in-message")}`}</Markdown>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleClose()}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => Auth.federatedSignIn({ provider: "Google" })}
                    startIcon={<GoogleIcon />}
                  >
                    {wide_screen && "Google"}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      Auth.federatedSignIn({ provider: "Facebook" })
                    }
                    startIcon={<FacebookIcon />}
                  >
                    {wide_screen && "Facebook"}
                  </Button>
                </CardActions>
              </React.Fragment>
            )}
          </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};