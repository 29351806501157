import React from "react";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
import MenuItem  from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";

import MoreIcon from "@material-ui/icons/MoreVert";

import Translate from "@material-ui/icons/Translate";

import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { Authenticator, userName } from './authenticator';
import { Avatar } from "@material-ui/core";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    menuItem: {
      backgroundColor: theme.palette.background.default,
      color: "white",
    },
  })
);

export default function PrimarySearchAppBar() {

  const { t, i18n } = useTranslation();
  const [user, setUser] = React.useState<any>();

  const [profileOpen, setProfileOpen] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Auth.currentAuthenticatedUser().then((_user) => {
        setUser(_user);
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  const handleProfileClose = (value?: string) => {
    setProfileOpen(false);
  };

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/app-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            base64
            tracedSVG
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
            originalImg
            originalName
          }
        }
      }
    }
  `);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [
    anchorTranslateEl,
    setAnchorTranslateEl,
  ] = React.useState<null | HTMLElement>(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = React.useState<null | HTMLElement>(null);

  const isAccountMenuOpen = Boolean(anchorEl);
  const isTranslateMenuOpen = Boolean(anchorTranslateEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTranslateMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorTranslateEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleTranslateMenuClose = async (language: string) => {
    // close the menus
    setAnchorTranslateEl(null);
    handleMobileMenuClose();
    // invalid click
    if (language.length !== 2) {
      return;
    }
    // change lang
    const currentLanguage = i18n.language;
    // console.log(
    //   "handleTranslateMenuClose",
    //   currentLanguage,
    //   language,
    //   window.location.href
    // );
    await i18n.changeLanguage(language);
    // move to new page
    if (window.location.pathname === "/") {
      window.location.assign(
        `${window.location.href}personas/${language}`
      );
    } else {
      window.location.assign(
        window.location.href.replace(`/${currentLanguage}`, `/${language}`)
      );
    }

  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const translateMenuId = "primary-search-translate-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isAccountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <MenuItem className={classes.menuItem} onClick={(e) => setProfileOpen(true)}>
        {t("profile")}
      </MenuItem>
      {/* <MenuItem className={classes.menuItem} onClick={handleAccountMenuClose}>
        {t("my-account")}
      </MenuItem> */}
    </Menu>
  );
  
  const translateMenu = () => {
    const languageCodes = t("language-codes")?.split(",");
    const languageText = t("language-texts")?.split(",");
    const zipped = _.zip(languageCodes, languageText);

    return (
      <Menu
        anchorEl={anchorTranslateEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={translateMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isTranslateMenuOpen}
        onClose={handleTranslateMenuClose}
      >
        {zipped.map((z) => (
          <MenuItem className={classes.menuItem} key={z[0]} onClick={() => handleTranslateMenuClose(z[0])}>
            {z[1]}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const profileBadgeColor = user ? "primary" : "error";
  const userInitials = userName(user)
    ?.split(" ")
    .map((n: string) => n[0])
    .join("");
  
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem className={classes.menuItem} onClick={handleTranslateMenuOpen}>
        <IconButton
          aria-label="translate"
          aria-controls={translateMenuId}
          aria-haspopup="true"
          color="inherit"
        >
          <Translate />
        </IconButton>
        <p>{t("translate")}</p>
      </MenuItem>

      <MenuItem className={classes.menuItem} onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="inherit"
          onClick={() => {
            setProfileOpen(true);
          }}
        >
          <Badge
            variant="dot"
            color={profileBadgeColor}
            invisible={profileBadgeColor == "primary"}
          >
            <Avatar alt="avatar">{userInitials}</Avatar>
          </Badge>
        </IconButton>
        <p>{t("profile")}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <Authenticator open={profileOpen} onClose={handleProfileClose} />

      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <div style={{ width: "4em" }}>
            <Link to={`/personas/${i18n.language}`}>
              <Img
                fluid={data.file.childImageSharp.fluid}
                className={classes.menuButton}
              />
            </Link>
          </div>
          <Typography className={classes.title} variant="h6" noWrap>
            Atheos
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="translate"
              aria-controls={translateMenuId}
              aria-haspopup="true"
              onClick={handleTranslateMenuOpen}
              color="inherit"
            >
              <Translate />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              // onClick={handleProfileMenuOpen}
              onClick={() => {
                setProfileOpen(true);
              }}
              color="inherit"
            >
              <Badge
                variant="dot"
                color={profileBadgeColor}
                invisible={profileBadgeColor == "primary"}
              >
                <Avatar alt="avatar">{userInitials}</Avatar>
              </Badge>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {translateMenu()}
    </div>
  );
}
