import React from "react";
import { Helmet } from "react-helmet";
import { truncate } from "lodash";
import { image_uri } from '../ViewUtility';

 
export const helmet = (
  title: string,
  description: string,
  image: string,
  url: string,
  origin: string,
) => {
  description = truncate(description, { length: 155 });
  const long_description = truncate(description, { length: 200 });
  const image_uri = `${origin}${image}`;
  return (
    <Helmet>
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {image && <meta name="image" content={image_uri} />}
      <link rel="canonical" href={url} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={long_description} />
      {image && <meta property="og:image" content={image_uri} />}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@Atheos" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={long_description} />
      {image && <meta name="twitter:image" content={image_uri} />}


    </Helmet>
  );
};