import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them)
const resources = require("../assets/i18n.translations.json");

const languageCodes = resources.en.ns["language-codes"];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    ns: ["ns"],
    defaultNS: "ns",
    fallbackLng: "en",
    keySeparator: ".", // we do use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

i18n.languages = languageCodes;

export default i18n;
