/**
 * Fix issues with multiple redirect urls.
 * see https://github.com/aws-amplify/amplify-cli/issues/2792
 * Allowing multiple redirectSignIn/redirectSignOut urls breaks federated auth #2792
 */
// import the generated config
import awsmobile from "../aws-exports";
// and the current host

const host =
  typeof window !== `undefined`
    ? window.location.host
    : process.env.GATSBY_APP_DOMAIN;

// console.log('aws_exports_fix before', JSON.stringify(awsmobile))

// if multiple hosts are configured,
// filter the redirects to the current host
if (awsmobile.oauth.redirectSignIn.includes(',')) {
  // filter the comma separated list of urls, compare host to current host  
  const filterHost = (url) => new URL(url).host === host;
  awsmobile.oauth.redirectSignIn = awsmobile.oauth.redirectSignIn
    .split(',')
    .filter(filterHost)
    .shift();
  awsmobile.oauth.redirectSignOut = awsmobile.oauth.redirectSignOut
    .split(',')
    .filter(filterHost)
    .shift();
}



// re-export
export default awsmobile;
