import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

import Link from "@material-ui/core/Link";
import Img from 'gatsby-image';
 
import { helmet } from './helmet';
import PrimarySearchAppBar from "./PrimarySearchAppBar";

import Carousel from "react-material-ui-carousel";

import '../i18n'
import Markdown from './Markdown';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
 
import AdSense from "react-adsense";

// Determines if the user is likely using an ad block extension
const checkAdBlocker = async () => {
  // Used to cache the result
  let isBlocked;

  async function tryRequest() {
    try {
      return fetch(
        new Request("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
          method: 'HEAD',
          mode: 'no-cors'
        }))
        .then(function(response) {
          // Google Ads request succeeded, so likely no ad blocker
          isBlocked = false;
          return isBlocked;
        }).catch(function(e) {
          // Request failed, likely due to ad blocker
          isBlocked = true;
          return isBlocked;
        });
    } catch (error) {
      // fetch API error; possible fetch not supported (old browser)
      // Marking as a blocker since there was an error and so
      // we can prevent continued requests when this function is run
      console.log(error);
      isBlocked = true;
      return isBlocked;
    }
  }

  return isBlocked !== undefined ? isBlocked : await tryRequest();
}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://twitter.com/peterboghossian">
        Peter Boghossian, LLC. All Rights Reserved.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
 

const AlbumLayout = (props: any) => {
  
  const children = props.children;
  const breadCrumbLinks = props.breadCrumbLinks;
  
  const title = props.title || "Set title";
  const description = props.description || "Set description";
  const fluid = props.fluid;
  const originalImg = fluid ? fluid.originalImg : "Set image";
  const language = props.language ? props.language : "en";

  const carouselItems = props.carouselItems || [
      {
        name: title,
        description: description,
        imageFile: [{childImageSharp: {fluid: fluid}}] 
      },
  ] ;

  
  const url = `${process.env.GATSBY_APP_DOMAIN}/${props.pagePath}`;

  const theme = useTheme();

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [adBlock, setAdBlock] = React.useState<boolean | null>(null);


  /**
   * Set language
   */
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const setLanguage = async () => {
        await i18n.changeLanguage(language);
      };    
      const queryAdblock = async () => {
        setAdBlock(await checkAdBlocker());
      };
      setLanguage();
      queryAdblock();
    }
    return () => {
      isMounted = false;
    };
  }, [language]);

  
  const Item = (props:any) => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={classes.albumGrid}
      >
        <Grid item>
          <div className={classes.albumImage}>
            <Img fluid={props.item.imageFile[0].childImageSharp.fluid} />
          </div>
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                {props.item.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h2">
                {props.item.description}
              </Typography>
              <Typography variant="h5" component="h2">
                {props.item.subdescription}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      // <Paper style={{ paddingLeft: "24px", color: "white" }}>
      //   <Typography>{props.item.name}</Typography>
      //   <Typography>{props.item.description}</Typography>
      //   {props.item.imageFile && (
      //     <Img fluid={props.item.imageFile[0].childImageSharp.fluid} />
      //   )}
      //   <Button className="CheckButton">Check it out!</Button>
      // </Paper>
    );
  } 

  // const items = [
  //   {
  //     name: "Random Name #1",
  //     description: "Probably the most random thing you have ever seen!",
  //     imageFile: null,
  //   },
  //   {
  //     name: "Random Name #2",
  //     description: "Hello World!",
  //     imageFile: null,
  //   },
  // ];

 

  return (
    <ThemeProvider theme={theme}>
      {helmet(
        title,
        description,
        originalImg,
        url,
        process.env.GATSBY_APP_DOMAIN
      )}
      <CssBaseline />

      <PrimarySearchAppBar />
      {breadCrumbLinks && breadCrumbLinks}

      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Carousel
            navButtonsAlwaysVisible={carouselItems.length > 1}
            indicators={carouselItems.length > 1}
          >
            {carouselItems.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {adBlock && <AdBlockDialog />}
            {!adBlock && (
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <div style={{ width: "276px" }}>
                    <AdSense.Google
                      client="ca-pub-8636622189012223"
                      slot="3748774839"
                      format="rectangle"
                    />
                  </div>
                </CardContent>
              </Card>
            )}
            {children}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography align="center" component="div">
          <a
            target="_blank"
            href="https://www.amazon.com/gp/product/B00LKBT0MC/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00LKBT0MC&linkCode=as2&tag=atheos0b-20&linkId=202577d85bb317c6aedef0372d47c746"
          >
            <img
              border="0"
              className="footerimage"
              src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00LKBT0MC&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=atheos0b-20"
            />
          </a>
          <img
            src="//ir-na.amazon-adsystem.com/e/ir?t=atheos0b-20&l=am2&o=1&a=B00LKBT0MC"
            width="1"
            height="1"
            border="0"
            alt=""
            style={{ border: "none !important", margin: "0px !important" }}
          />
          <a
            target="_blank"
            href="https://www.amazon.com/gp/product/0738285323/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=0738285323&linkCode=as2&tag=atheos0b-20&linkId=b8b4ef7521fdda25d5f5adeea80bc9a1"
          >
            <img
              border="0"
              className="footerimage"
              src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=0738285323&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=atheos0b-20"
            />
          </a>
          <img
            src="//ir-na.amazon-adsystem.com/e/ir?t=atheos0b-20&l=am2&o=1&a=0738285323"
            width="1"
            height="1"
            border="0"
            alt=""
            style={{ border: "none !important", margin: "0px !important" }}
          />
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </ThemeProvider>
  );
}

export const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  breadCrumb: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: "24px"
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 8, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    borderRadius: 20,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.paper,
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    height: 0,
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  albumImage: {
    minWidth: 180,
    minHeight: 180,
  },
  albumGrid: {
    color: theme.palette.text.paper,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


function AdBlockDialog() {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const { t } = useTranslation();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      //Don't open on home page
      if (
        !location.pathname.match(/\/personas\/..$/) &&
        !location.pathname.match(/\/personas\/..\/$/)
      ) {
        setOpen(true);
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title" color="secondary">
          <span
            style={{ color: theme.palette.secondary.main, fontSize: "larger" }}
          >
            {t("ad-blocker-title")}
          </span>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          {/* <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          ></DialogContentText> */}
          <span
            style={{ color: theme.palette.text.paper}}
          >
            <Markdown>{t("ad-blocker-instructions")}</Markdown>
          </span>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default AlbumLayout;


