/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:291cc596-2edd-4b57-839c-7afdd6b41e62",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_NwOZU7JDR",
    "aws_user_pools_web_client_id": "71im1bdlgh3gin1qebv9bc29ro",
    "oauth": {
        "domain": "atheos28df510a-28df510a-master.auth.us-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,https://atheos-app.org/",
        "redirectSignOut": "http://localhost:19006/,https://atheos-app.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://eyd6mpvvbff6rdrc34v64ecsq4.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-jdh4zqvi4bfhvdegsty3r7ducq"
};


export default awsmobile;
